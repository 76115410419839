/* Fonts START */
@font-face {
    font-family: Anderson Grotesk;
    src: url(assets/fonts/AndersonGrotesk/AndersonGroteskLight.otf);
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: Anderson Grotesk;
    src: url(assets/fonts/AndersonGrotesk/AndersonGroteskLightOblique.otf);
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: Anderson Grotesk;
    src: url(assets/fonts/AndersonGrotesk/AndersonGroteskRegular.otf);
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: Anderson Grotesk;
    src: url(assets/fonts/AndersonGrotesk/AndersonGroteskOblique.otf);
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: Anderson Grotesk;
    src: url(assets/fonts/AndersonGrotesk/AndersonGroteskBold.otf);
    font-weight: 700;
    font-display: swap;
}

@font-face {
    font-family: Anderson Grotesk;
    src: url(assets/fonts/AndersonGrotesk/AndersonGroteskBoldOblique.otf);
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'slick';
    font-weight: normal;
    font-style: normal;
    font-display: swap;

    src: url('assets/fonts/slick.eot');
    src: url('assets/fonts/slick.eot?#iefix') format('embedded-opentype'), url('assets/fonts/slick.woff') format('woff'), url('assets/fonts/slick.ttf') format('truetype');
}

/* Fonts END */

html,
body {
    width: 100%;
    height: 100%;
}

body {
    font-family: Anderson Grotesk, serif;
    font-size: 1rem;
    color: #333;
}

.hel {
    font-family: helvetica, serif;
}

a {
    -webkit-transition: all .35s;
    transition: all .35s;
    cursor: pointer;
}

.text-white {
    color: #fff;
}

.text-green {
    color: #11D2C2;
}

.dgreen {
    background-color: #00786F;
}

.grey {
    background-color: #EEEEEE;
}

.black {
    background-color: #333;
}

a:hover,
a:focus,
button:hover,
button:focus {
    opacity: 0.5;
    color: inherit;
    text-decoration: none;
}

.img-fluid {
    width: 100%;
}

hr {
    max-width: 100px;
    margin: 25px auto 0;
    border-width: 1px;
    border-color: rgba(34, 34, 34, 0.1);
}

hr.light {
    border-color: white;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: Anderson Grotesk, serif;
    font-weight: 400;
    letter-spacing: 1px;
}

p {
    line-height: 1.5;
    margin-bottom: 20px;
}

section {
    padding: 8rem 0;
}

section h1 {
    padding-bottom: 4rem;
    text-align: center;
}

button,
button:focus {
    outline: none;
    box-shadow: none;
}

.btn {
    -webkit-transition: all .35s;
    transition: all .35s;
    margin-top: 5px;
}

.navbar {
    padding: 5px;
    font-weight: 600;
    border: 0;
    background-color: transparent;
}

.navbar .navbar-brand, .navbar .navbar-brand:focus {
    color: #11D2C2;
    font-size: 2em;
    transform: scale(.8)
}

.navbar .navbar-nav {
    letter-spacing: 1px;
    margin-right: 10px
}

.navbar .navbar-nav li {
    overflow: hidden
}

.navbar .navbar-nav li a, .navbar .navbar-nav li a:focus {
    color: #11D2C2;
    padding-bottom: 10px
}

.navbar .navbar-nav li a:hover {
    color: #11D2C2
}

.navbar .navbar-nav .nav-link {
    padding-left: 1rem;
    padding-right: 1rem;
}

.navbar .navbar-nav .nav-link.active {
    color: #fff;
    opacity: 1;
}

.navbar-dark .navbar-toggler {
    border: 0;
}

.btn {
    background-color: #11D2C2;
    color: #333;
    border: 0;
    border-radius: 0;
    font-weight: 500;
    font-size: 1.125rem;
    padding: 0.5rem 0.5rem 0.25rem;
}

header {
    color: #fff;
    overflow: hidden;
    min-height: 640px;
    height: 100vh;
    background: linear-gradient(-45deg, #111, #222, #444);
    background-size: 400% 400%;
    -webkit-animation: Gradient 8s ease infinite;
    -moz-animation: Gradient 8s ease infinite;
    animation: Gradient 8s ease infinite;
}

header .intro-text .name {
    display: block;
    font-weight: bolder;
    font-size: 3em;
    color: #11D2C2;
}

header .intro-text .tagline {
    font-size: 1.125em;
    font-weight: 300;
    margin-bottom: 10px
}

@-webkit-keyframes Gradient {
    0% {
        background-position: 0 50%
    }
    50% {
        background-position: 100% 50%
    }
    100% {
        background-position: 0 50%
    }
}

@-moz-keyframes Gradient {
    0% {
        background-position: 0 50%
    }
    50% {
        background-position: 100% 50%
    }
    100% {
        background-position: 0 50%
    }
}

@keyframes Gradient {
    0% {
        background-position: 0 50%
    }
    50% {
        background-position: 100% 50%
    }
    100% {
        background-position: 0 50%
    }
}

.jaryl-img img {
    position: relative;
    z-index: 2;
    border-radius: 5px 5px 5px 5px;
}

.jaryl-img .green-box {
    content: "";
    display: block;
    width: 90%;
    height: 100%;
    background-color: #89E4DD;
    position: absolute;
    z-index: 1;
    left: -2rem;
    bottom: -2rem;
    border-radius: 5px 5px 5px 5px;
}

.year, .org {
    font-weight: 500;
    font-size: 1.5rem;
}

.title {
    font-size: 1.125rem;
    margin-bottom: 1rem;
    font-style: italic;
}

.exp-wrapper .exp-line,
.edu-wrapper .edu-line {
    display: block;
    height: 100%;
    width: 2px;
    position: absolute;
    left: -1.5px;
    background-color: #11D2C2;
}

.exp-circle,
.edu-circle {
    display: block;
    width: 0.6666rem;
    height: 0.6666rem;
    background-color: #11D2C2;
    position: absolute;
    border-radius: 0.3333rem;
    left: -0.3333rem;
    top: 0.5rem;
}

.exp-row,
.edu-row {
    margin-bottom: 2rem;
    position: relative;
}

.exp-row:last-child,
.edu-row:last-child {
    margin-bottom: 0;
}

.exp-row img,
.edu-row img {
    width: 66%;
}

.skill-icon {
    display: block;
    height: 100px;
    width: 100px;
    margin: 0 auto 2rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 66%;
}

.skill-icon.skill-icon-1 {
    background-image: url(assets/img/skills/skill-1.svg);
}

.skill-icon.skill-icon-2 {
    background-image: url(assets/img/skills/skill-2.svg);
}

.skill-icon.skill-icon-3 {
    background-image: url(assets/img/skills/skill-3.svg);
}

.skill-icon.skill-icon-4 {
    background-image: url(assets/img/skills/skill-4.svg);
}

.skill-icon.skill-icon-5 {
    background-image: url(assets/img/skills/skill-5.svg);
}

.skill-icon.skill-icon-6 {
    background-image: url(assets/img/skills/skill-6.svg);
}

.skill-icon.skill-icon-7 {
    background-image: url(assets/img/skills/skill-7.svg);
}

.skill-icon.skill-icon-8 {
    background-image: url(assets/img/skills/skill-8.svg);
}

.skill-icon.skill-icon-9 {
    background-image: url(assets/img/skills/skill-9.svg);
}

.skill img {
    display: block;
    margin: 0 auto;
    width: 66%;
}

.skill .title {
    font-weight: 500;
    font-size: 1.5rem;
    text-align: center;
}

.skill .desc {
    text-align: center;
    width: 90%;
    margin: 0 auto 4rem;
}

.social-icons {
    text-align: center;
}

.social-icons img {
    margin: 2rem 0.5rem;
}

.project {
    cursor: pointer;
    position: relative;
    overflow: hidden;
    padding-bottom: 15px;
}

.project .overlay {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background-color: rgba(0, 0, 0, 0.6666);
    color: #fff;
    opacity: 0;
    transform: skewX(-45deg);
    -webkit-transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    -o-transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.project .title {
    display: flex;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    font-size: 1.5rem;
    opacity: 0;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.project:hover .overlay {
    transform: skewX(-10deg);
    opacity: 1;
}

.project:hover .title {
    opacity: 1;
}

.modal-content {
    background-color: #333;
    color: #fff;
}

.modal-header {
    margin: 0 auto;
    border-bottom: 0;
    border-radius: 0;
}

.modal .close {
    margin: -1rem -1rem -1rem 1rem;
    color: white;
    position: absolute;
    right: 2rem;
}

.close svg {
    height: 14px;
    width: 14px;
}

#contact a {
    color: #fff;
}

form {
    margin: 4rem 0;
}

form input {
    width: 48%;
}

form input:nth-child(1) {
    width: 48%;
    float: right;
}

form textarea {
    width: 100%;
}

.form-control {
    background: transparent;
    color: #fff;
    border: 1px solid #89E4DD;
    padding: 0.25rem 0.5rem;
    border-radius: 0;
    position: relative;
    z-index: 1
}

.form-control:focus {
    outline: none;
    color: #fff;
    box-shadow: none;
    background: transparent;
    border: 1px solid #fff;
}

form button.btn {
    width: 160px;
    display: block;
    margin: 0 auto;
}

.form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #89E4DD;
    opacity: 1; /* Firefox */
}

.form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #89E4DD;
}

.form-control::-ms-input-placeholder { /* Microsoft Edge */
    color: #89E4DD;
}

.form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #89E4DD;
    opacity: 1; /* Firefox */
}

.form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #89E4DD;
}

.form-control::-ms-input-placeholder { /* Microsoft Edge */
    color: #89E4DD;
}


.floating-label-form-group {
    position: relative
}

.floating-label-form-group label {
    display: block;
    z-index: 0;
    position: relative;
    padding: 0.25rem 0.5rem;
    top: 2em;
    margin: 0;
    line-height: 1.5em;
    vertical-align: baseline;
    opacity: 0;
    text-align: left;
    transition: top .3s ease, opacity .3s ease
}

.floating-label-form-group-with-value label {
    opacity: 1;
    top: 0;
    color: #fff;
}

.floating-label-form-group-with-focus label {
    color: #89E4DD
}

@media screen and (max-width: 480px) {
    form {
        margin: 2rem 0;
    }

    section {
        padding: 4rem 0;
    }
}
